<template>
  <div>
    <div>
      <!-- <div   v-lazy:background-image="require('./Txzstp_img/img6.png')"> -->
      <div class="img_1">
        <div class="start">
          <img
            v-lazy="require('./Txzstp_img/ALEH5OIg.webp')"
            class="start-img"
          />
        </div>
        <div class="bannert">
          <span class="txt1">知识图谱</span>
          <span class="word1">用年轻人的优秀创意搭建一座时空桥梁，把千年前古人和现代人的生活方式做一个连接，创造出属于这个时代的文物时尚</span>
        </div>
      </div>
      <div class="img_2">
        <div class="img-2">
          <img
            v-lazy="require('./Txzstp_img/HMDprWoN.webp')"
            class="img-1"
          />
          <div class="literal">
            <span class="txt2">知识图谱星空图</span>
            <span class="word2">以结构化的形式描述客观世界中概念、实体及其关系，便于更好的管理、计算和理解海量信息</span>

            <div
              class="txt_2"
              @click="parentUrl()"
            >
              <span class="txt_2_1">查看详情 ></span>
            </div>

          </div>
        </div>

        <!-- 对话框 -->
        <!-- <div class="dilog" v-show="showdia">
      <div class="close" @click="closedel"></div>
    </div> -->
      </div>
      <!-- 遮盖层 -->
      <!-- <div class="mocks" v-show="showdia" @click="closedlgs"></div> -->
      <!-- <div class="img_3_2">
   <img src="./Txzstp_img/img7.png" class="img_3_1">
   <div class="txt3_1_1">联合出品</div>
 </div> -->
    </div>
    <div>
      <Header></Header>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
// 超链接

export default {
  components: {
    Header,
    Footer,

  },

  data() {
    return {
      showdia: false,
      wisdomUrl: ""
    };
  },


  methods: {
    opendel() {
      this.showdia = true;
    },
    closedel() {
      this.showdia = false;
    },
    closedlgs() {
      this.closedel();
    },
    parentUrl() {
      this.smarturl()
    }
  },
};
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.img_1 {
  height: 600px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.start-img {
  height: 600px;
}

.start {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
}
.bannert {
  position: absolute;
  left: 361px;
  top: 230px;
}
/* 对话框 */
.dilog {
  z-index: 999;
  width: 600px;
  height: 400px;
  background: url("./Txzstp_img/img.png") no-repeat center;
  background-size: 100% 100%;
  background-color: #fff;
  position: absolute;
  left: 600px;
  top: 800px;
}
.mocks {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.txt1 {
  display: block;
  overflow-wrap: break-word;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  letter-spacing: 3.4285714626312256px;
}
.txt1 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.word1 {
  width: 697px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  align-self: center;
  margin-top: 20px;
}
.word1 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.txt_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid rgba(204, 204, 204, 1);
  // border: 1px solid red;
  margin: 0 auto;
  margin-top: 36px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  .txt_2_1 {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
.img_2 {
  height: 420px;
  width: 100%;
  // background: url("./Txzstp_img/img3.png") no-repeat center;
  // background-size: 100% 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
}
.img-1 {
  height: 420px;
  width: 1200px;
}
.img-2 {
  height: 420px;
  width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 150px;
    height: 150px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 420px;
    width: 1200px;
  }
}
.literal {
  position: absolute;
  top: 0px;
  left: 520px;
}
.txt2 {
  width: 289px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  letter-spacing: 1.25px;
  line-height: 56px;
  margin: auto;
  padding-top: 120px;
}
.word2 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 32px;
  margin: auto;
  margin-top: 12px;
}
// .txt_2_1 {
//   color: rgba(255, 255, 255, 1);
//   font-size: 20px;
//   letter-spacing: 1px;
//   line-height: 28px;
// }
.img_3_2 {
  height: 247px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close {
  width: 25px;
  height: 24px;
  background: url("./Txzstp_img/closeone.png") no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  top: -35px;
  right: -24px;
  z-index: 5;
}
.close:hover {
  cursor: pointer;
}
// .txt3 {
//   width: 16px;
//   height: 16px;
// }
.txt3_1_1 {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  height: 45px;
  line-height: 45px;
}
.img_3_1 {
  width: 359px;
  height: 47px;
}
</style>
